<template>
  <div class="page__wrapper">
    <ag-grid
      ref="dealerGradeGrid"
      pagination
      edit-type="fullRow"
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :pagination-page-size="tablePageInfo.pageSize"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
  </div>
</template>

<script>
import pageMixins from '@/components/base/simple-table/mixin'

const BASEURL = {
  list: '/jlr/tt/selfExaminationData/listPeriod',
  office: '/system/office/list'
}

export default {
  name: 'UnsubmittedDealer',
  mixins: [pageMixins],
  data () {
    return {
      searchModel: { period: this.$moment().subtract(1, 'months').format('YYYYMM') },
      tableData: [],
      rowData: {},
      dealerList: [],
      groupList: [],
      gridOptions: {},
      isViewFlag: false,
      groupLoading: false,
      frameworkComponents: {}
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleExportClick: this.handleExportClick,
      handleSaveClick: this.handleSaveClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.month'),
          component: { clearable: false, type: 'month', valueFormat: 'yyyyMM' }
        },
        {
          prop: 'region',
          type: 'Select',
          col: { xs: 4, sm: 4, md: 4 },
          component: { optionList: this.$getDictList('sys_office_region'), clearable: true },
          label: this.$t('dealer_finance.area')
        },
        {
          prop: 'dealerParent',
          type: 'SearchableInput',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.group'),
          component: {
            remoteMethod: this.getGroupList,
            loading: this.groupLoading,
            optionList: this.groupList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getGroupList()
            }
          }
        },
        {
          prop: 'dealer',
          label: this.$t('dealer_finance.dealer'),
          type: 'SearchableInput',
          col: { xs: 4, sm: 4, md: 4 },
          component: {
            remoteMethod: this.getDealerList,
            loading: this.dealerLoading,
            optionList: this.dealerList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getDealerList()
            }
          }
        },
        { slotName: 'tableOperation', col: { xs: 8, sm: 8, md: 8 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.dealerName'),
          field: 'dealer.name',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.dealerCode'),
          field: 'dealer.code',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.area'),
          field: 'region',
          valueFormatter: params => this.$getDictLabel({ type: 'sys_office_region', value: params.value }),
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.group'),
          field: 'dealerParent',
          valueFormatter: params => params.data.dealerParent ? (params.data.dealerParent.name || '') : '',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return (valueA && valueB) ? valueA.name.localeCompare(valueB.name) : -1
          },
          minWidth: 160
        }
        // {
        //   headerName: this.$t('system_office.master'),
        //   field: 'master',
        //   minWidth: 200
        // },
        // {
        //   headerName: this.$t('system_office.email'),
        //   field: 'email',
        //   minWidth: 200
        // },
        // {
        //   headerName: this.$t('system_office.phone'),
        //   field: 'phone',
        //   minWidth: 200
        // }
      ]
    }
  },
  beforeMount () {
    // this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      if (this.searchModel.dealer) {
        params.filter.push({ left: 'dealer.id', operate: '=', right: this.searchModel.dealer.id })
      }
      if (this.searchModel.dealerParent) {
        params.filter.push({ left: 'dealerParent.id', operate: '=', right: this.searchModel.dealerParent.id })
      }
      params.page = { orderBy: 'createDate' }
      params.filter.push({ left: 'bizStatus', operate: '=', right: 'V1' })
      params.filter.push({ left: 'id', operate: 'is null' })
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    getDealerList (name) {
      this.dealerLoading = true
      const params = {
        filter: [{ left: 'category', operate: '=', right: 'sys_office_category_3' }]
      }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'code', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then(resp => {
          const respData = resp.data
          this.dealerList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.dealerLoading = false
        })
    },
    getGroupList (name) {
      this.groupLoading = true
      const params = {
        filter: [{ left: 'category', operate: '=', right: 'sys_office_category_2' }]
      }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'nameEn', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then(resp => {
          const respData = resp.data
          this.groupList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.groupLoading = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
